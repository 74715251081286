@import '../variable/Theme.scss';

.popup-overlay {
  background-color: rgba(10, 10, 10, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}

.popup-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 520px;
  height: 21vh;
  z-index: 200000;
  padding: 0.5rem;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  font-size: 19px !important;
  background-color: rgba(250, 250, 250, 0.9);

  p {
    font-family: Helvetica, Arial, sans-serif;
    color: darkblue;
  }

  &__msg {
    font-style: italic;
  }

  &__button {
    font-size: 24px !important;
    display: flex;
    padding: 1rem;
    gap: 1rem;
    cursor: pointer;

    button {
      color: white;
      border: none;
      background: $bg-gradient-button;
      padding: 0.5rem 1.5rem;
      border-radius: 1.5rem;
    }
  }
}

@media screen and (max-width: 560px) {
  .popup-container {
    font-size: 16px !important;
    width: 222px;
    height: 140px;
    z-index: 200000;
    border-radius: 1.5rem;
    &__button {
      font-size: 24px !important;
    }
  }
}
