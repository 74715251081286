@mixin btn-pagination {
  // padding: 0.1rem 0.4rem;
  margin: 0.1rem;
  color: #090081;
  background: none;
  border-color: #891915;
  border-radius: 0.3rem;
}

.previous-button {
  @include btn-pagination;
}

.next-button {
  @include btn-pagination;
}

.pagination-container {
  padding: 0.1rem 0.4rem;
  width: fit-content;
  // margin: 1rem auto;
  margin: 0 auto;
  color: #090081;
}

.pagination-span {
  padding: 0.1rem 0.2rem;
  margin: 1rem 0.3rem;

  font-size: 40px;
  color: #090081;
}

h2 {
  text-align: center;
}

main > div > div > button {
  padding: 0.1rem 0.4rem;
  margin: 0 0.2rem;
  font-size: 16px;
  color: #090081;
  background: none;
  border-color: #891915;
  border-radius: 0.3rem;

  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  }
}
