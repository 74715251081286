h2 {
  margin: 2rem 0;
}

h3 {
  text-align: center;
}

.profitable-form {
  box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 20%);
}

.profitable-form > label {
  text-align: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.profitable-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  max-width: 700px;
  margin: 0.5rem auto;
  padding: 0 1rem;
  color: white;
  border: 0.5px solid #891915;
  box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 20%);
  border-radius: 0.5rem;
}

.profitable-data {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #090081;
  text-transform: uppercase;

  & p:first-child {
    font-weight: 600;
    width: 40%;
  }

  & p:nth-child(2) {
    padding: inherit;
    padding: 0 0.5rem;
    font-style: italic;
  }
}

.profitable-btns {
  width: 25%;
  display: flex;
  width: 200px;
  padding: 0.1rem 0.1rem;
  margin: 0 auto;
  gap: 0.5rem;
}

.profitable-btn {
  border: inherit;
  justify-content: center;
  margin: 0.6rem auto;
  padding: 0.7rem;
  color: white;
  background-color: #090081;
  border-radius: 0.5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 360px) {
  .button-active {
    display: flex;
  }
}

button {
  &:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }

  &:active {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

.profitable-padd {
  padding: 0.7rem 0 0.2rem 0;
}
.delete {
  background-color: #891915 !important;
}

.text-align-c {
  text-align: center;
}

.text-align-ce {
  color: #c0301e;
  text-align: center;
}

.beneficiary-alert {
  color: #891915;
}

.h3-profitable {
  margin: 1rem 0;
}

.red {
  background-color: unset;
  font-size: 16px;
  color: #891915;
  cursor: crosshair;
}

// Array titles
.profitable-titles {
  display: flex;
  margin: 0.5rem auto;
  padding: 0 1rem;
  border: 0.5px solid #891915;
  box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 20%);
  border-radius: 0.5rem;
}

.profitable-name-id-action {
  display: flex;
  width: 100%;
}

.profitable-name-id {
  gap: 7rem;
  justify-content: space-evenly;
  display: flex;
  width: 64%;
}

.profitable-action {
  width: 25%;
  margin-left: 7rem;
}

// Media query
@media screen and (max-width: 680px) {
  .profitable-container {
    flex-direction: column;
    padding: 0 0.5rem;
  }

  .profitable-data {
    font-size: 15px;
  }

  .profitable-titles {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .profitable-form {
    padding: 0 0.5rem;
  }

  .profitable-data {
    height: 27px;
    flex-direction: column;
    font-size: 12px;
    margin: 0.9rem 0 0.9rem 0;
  }
}

@media screen and (max-width: 350px) {
  .profitable-data {
    font-size: 10.5px;
  }
}
