.search-container {
  font-family: Helvetica, Arial, sans-serif;
  width: 600px;
  text-align: center;
  padding: 1rem;
  margin: 1rem auto 1rem;
  font-weight: 600;
  border: 0.5px solid #891915;
  box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 20%);
  border-radius: 0.5rem;

  div.form-container {
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem;
  }

  div.options-writing {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    gap: 1rem;
    margin: 0 0 1rem 2rem;

    input {
      margin: 0;
      width: 170px;
    }

    select {
      width: 210px;
      color: black;
    }

    select,
    input {
      border: 2px solid gray;
      border-radius: 0.5rem;
      text-transform: uppercase;
      font-size: 16px;
      text-align: center;
      height: 40px;
    }
  }

  div.action {
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    .action-button {
      padding: 0.7rem;
      border: inherit;
      border-radius: 0.5rem;
      background-color: #090081;
      font-weight: 600;
      color: white;
      text-transform: uppercase;

      &:disabled {
        background-color: lightgray !important;
      }
    }

    .reset-button {
      background-color: #891915;
    }
  }

  .msg-no-account {
    color: #891915;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    padding: 0;
  }
}

@media screen and (max-width: 892px) {
  .search-container {
    width: fit-content;
    padding: 0.5rem;

    div.options-writing {
      flex-direction: column;
      margin: 0 0 1rem 0rem;

      select {
        width: 210px !important;
        font-size: 15px;
      }

      input {
        width: 209px;
        order: -1;
        font-size: 15px;
      }
    }

    div.action {
      gap: 0.5rem;

      button {
        font-size: 11px;
      }
    }
  }
}
