@import '../../style/variable/Theme.scss';

.user-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.9rem;
  padding-right: 1rem;
  width: 100%;
  height: 39px;
  background: $bg-gradient-user;
  // fixed bar
  position: sticky;
  top: 0;
  z-index: 30000;

  .user-flex {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-flex-data {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .user-info {
    padding-right: 0.2rem;
    font-size: 11px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      max-width: 0;
    }

    to {
      opacity: 1;
      max-width: 200px;
    }
  }

  .welcome {
    white-space: pre;
    word-wrap: normal;
    overflow: hidden;
    width: 200px;
    opacity: 0;
    max-width: 0;
    animation: fadeIn 2s 0.1s forwards;
  }

  li {
    padding-right: 0.4rem;
  }

  span {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-style: italic;
    text-transform: uppercase;
    color: white;
    opacity: 0.8;
    font-weight: 700;
    animation: blinker 2s linear infinite;

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
  }
}

.items {
  list-style: none;
}

@media screen and (max-width: 668px) {
  .user-container {
    .user-info {
      font-size: 8px !important;
    }
  }
}

@media screen and (max-width: 540px) {
  .span-none {
    display: none;
  }
}
