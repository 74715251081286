.error-content {
  transform: translateY(7rem);
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  font-family: 'Helvetica';
  color: #090081;
  display: flex;
  text-align: center;
  flex-direction: column;
  height: fit-content;
  border: 2px solid #c0301e;
  border-radius: 15px;
  font-size: 16px;
  padding: 0 3rem 2.5rem;
  gap: 3rem;
  border: 3px solid #c0301e;
  margin: 0 auto;
}

@media screen and (max-width: 670px) {
  .error-content {
    transform: translateY(0.3rem);
  }

  .error-message {
    width: 30vw;
    gap: 0;
  }
}
