.transa-btn {
  border: inherit;
  justify-content: center;
  margin: 1.5rem auto;
  padding: 0.7rem;
  color: white;
  background-color: #090081;
  border-radius: 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
}

.transa-form {
  box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 20%);
}

.transa-form > label {
  text-align: center;
}

button {
  &:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }

  &:active {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

h3 {
  margin: 2rem 0;
}

.text-align-c {
  text-align: center;
}

.text-align-ce {
  color: #c0301e;
  text-align: center;
}
