@import '../variable/Theme.scss';

h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.money-page {
  font-family: Helvetica, Arial, sans-serif;
  color: #090081;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .texts-blocs {
    z-index: 1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .two-grids {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 90vw;

      .card-grid {
        flex-direction: column;
      }

      img {
        margin: 1rem;
        border: 1px solid gray;
        border-radius: 1rem;
        height: 400px;
      }

      h4 {
        margin: 1rem;
        font-weight: 700;
        text-decoration: underline;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .two-grids {
    img {
      margin: 0.5rem;
      height: 300px !important;
    }

    h4 {
      margin: 0.5rem;
    }
  }
}

@media screen and (max-width: 660px) {
  .two-grids {
    img {
      margin: 0.5rem;
      height: 200px !important;
    }

    h4 {
      margin: 0.5rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .two-grids {
    width: 30vw !important;
  }
}
