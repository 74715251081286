@import '../variable/Theme.scss';

.burger-container {
  display: none;
  max-height: 0;
  transition: max-height 0.35s ease-out;
  overflow: hidden;
  background: linear-gradient(to right, #28356a 0%, #059be0 51%, #28356a 100%);
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.open {
  max-height: 500px;
  transition: max-height 0.45s ease-in;
}

.close {
  transition-duration: 0.45s ease-out;
}

@media screen and (max-width: 667px) {
  .burger-container {
    display: flex;
  }
}
