#root > div.page-content > main > div > div:nth-child(1) > div > div > div > p {
  text-align: start;
}

div.saving-flex > div > p {
  text-align: start;
}

.personnal-space {
  font-family: Helvetica, Arial, sans-serif;
  color: #090081;
  display: flex;
  align-items: center;
  justify-content: center;

  .texts-blocs {
    z-index: 1;
    text-transform: uppercase;
    margin: 0 auto;

    .saving-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem 0;
      margin: 2rem 0;
      gap: 1rem;
      width: 100vw;
      box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 0.2);

      h3 {
        padding: 0.1rem;
        margin: 0.1rem;
        font-size: 16px;
        display: block;
        color: #c0301e;
      }

      .saving-money {
        align-items: space-around;
        display: flex;
        font-size: 13px;
        justify-content: space-around;
        width: 70vw;

        p {
          font-size: 16px;
        }

        .saving-account-text {
          display: flex;
        }
      }

      .saving-sun {
        margin: auto 1rem auto 0;
      }

      .saving-bolded {
        font-weight: 700;
      }
    }
  }
}
.saving-position {
  position: relative;
  top: 11px;
}

.saving-flex {
  display: flex;
  font-size: 16px;
}

.text-styled {
  font-size: 16px;
}

.flex-user {
  display: flex;
  flex-direction: column;
  // box-shadow: 1px 1px 3px 1.5px gray;
  // border: 1px solid #891915;
  // border-radius: 0.5rem;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  text-align: center;

  p:nth-child(2),
  p:nth-child(4) {
    padding-bottom: 0.4rem;
  }

  p {
    padding: 0.5rem;
  }

  .flex-id {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    p {
      padding: 0;
    }
  }
}

.textarea-none {
  width: 0px;
  opacity: 0;
}

.account-btn {
  height: 27px;
  color: #090081;
  background: none;
  font-weight: 600;
  font-size: 14px;
  border-color: #891915;
  border-radius: 0.3rem;
  width: 80px;
  transform: translate(0, -2px) !important;
}

.text-styled {
  font-style: italic;
}

.h-subtitle {
  margin-top: 0.5rem;

  &:nth-child(2) {
    margin-top: 1rem;
  }
}

.contract-block {
  width: 100px;
  height: 100px;
  background-color: teal;
}

@media screen and (max-width: 640px) {
  .saving-money {
    flex-direction: column;
    align-items: center;
    top: 0;
  }

  .saving-flex {
    font-size: 14px;
  }

  .text-styled {
    font-size: 14px;
  }

  .flex-id {
    flex-direction: column;
    gap: 0;
  }

  .saving-position {
    position: relative;
    top: 0;
  }

  .started-mobile {
    text-align: start !important;
  }
}
