.grid-row {
  display: flex;
  width: 90vw;
  margin: 0 auto;
  text-transform: uppercase;
  background-color: #fff6;

  &.head {
    padding-bottom: 0.9rem;
    margin-bottom: 0.9rem;
    border-bottom: 2px solid #c0301e;
  }
}

.grid-cell {
  flex: 1;
  padding: 0.6rem 0;
  text-align: center;
  min-width: 4rem;

  &:nth-child(2) {
    border-right: 2px solid #c0301e;
    border-left: 2px solid #c0301e;
    flex: 3;
    // text-transform: initial;
  }
}

.plus {
  font-weight: 700;
  color: steelblue;
}

.minus {
  font-weight: 700;
  color: #c0301e;
}

.text-align-c {
  text-align: center;
}

.text-align-ce {
  color: #c0301e;
  text-align: center;
}

.personnal-operation {
  font-family: Helvetica, Arial, sans-serif;
  color: #090081;
  // margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .operation-span-text {
    text-align: center;
    display: block;
    color: #090081;
    font-weight: 700;
  }
}

.texts-blocs {
  z-index: 1;
  margin: 0 auto;

  .operation-account {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0;
    margin: 1.5rem 0;
    // width: 100vw;
    border-radius: 0.5rem;
    box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 0.2);
  }
}

.operation-icon {
  height: 20px !important;
  padding: 0 0.3rem;
  transform: translateY(4px);
}

// Responsive
.mobile {
  display: none;
}

.date {
  color: grey;
  justify-content: center;
}

.amount {
  justify-content: center;
}

@media screen and (max-width: 650px) {
  .grid-cell:not(:nth-child(2)) {
    display: none;
  }

  .grid-cell:nth-child(2) {
    border-left: none;
    border-right: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .mobile {
    display: flex;
  }

  .operation-icon {
    display: none;
  }
}
