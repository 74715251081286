@import '../variable/Theme.scss';

// Fresco background image
.fresco-container {
  display: flex;

  img {
    box-shadow: 5px 5px 10px gray;
  }
}

.less-spacement {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-top: 0;
  margin-bottom: 0 !important;
}

div.fresco-texts > img {
  background-size: contain;
  width: 65%;
  display: flex;
  margin: 1.5rem auto;
}

// Connection place
.input-login {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 5px;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #891915;
  border-radius: 1rem;
  width: 200px;
  height: 30px;
  margin: 0 auto;
}

@mixin background-image {
  display: flex;
  margin: 0 auto;
}

.login-page {
  font-family: Helvetica, Arial, sans-serif;
  color: #090081;
  // height: 100vh;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  // Two backgrounds
  .rosace-image {
    @include background-image;
    z-index: -1;
    opacity: 0.15;
  }

  .acronym-image {
    @include background-image;
    z-index: 0;
    opacity: 0.05;
  }

  .texts-blocs {
    z-index: 1;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2rem;
    width: 400px;

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
    }

    div:nth-child(2) > input[type='password'] {
      width: 190px;
      display: flex;
      margin: 0 auto;
      text-align: center;
      height: 25px;
      border-radius: 0.5rem;
      background-color: #ececec;

      &:hover {
        border: 3px solid #891915;
      }
    }

    .button-submit {
      width: 140px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      color: white;
      border-radius: 0.5rem;
      border: 1px solid #871814;
      background: $bg-gradient-button;
      cursor: pointer;

      &:hover {
        opacity: 0.95;
        transform: scale(1.05);
      }

      &:active {
        opacity: 0.9;
        transform: scale(1.1);
      }
    }
  }
}

.user-account {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  p:first-child {
    margin-bottom: 0.8rem;
  }

  img {
    margin-left: auto;
    margin-right: auto;
  }
}

.display-msg {
  color: #cd171a;
}

.array-numbers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 200px;
  margin: 0 auto;
  padding: 0.6rem;
  border-radius: 0.5rem;
  background-color: #f8f9fb;
  border: 1px solid #891915;

  button {
    width: 40px;
    height: 40px;
    padding: 0.5rem;
    margin: 0.2rem;
    border-radius: 50%;
    border: none;
    background-color: white;
    cursor: pointer;
  }
}

// Gestion du mot de passe caché
.key-input {
  display: flex;
  justify-content: center;
  position: relative;
  gap: 5px;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #891915;
  border-radius: 1rem;
  width: 200px;
  height: 30px;
  margin: 0 auto;

  .password-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;
  }

  .clear-btn {
    position: absolute;
    right: 12px;
    top: 7px;
    align-self: center;
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      fill: #891915;
    }
  }
}

.space-icon {
  display: flex;
  margin: 0 auto;
}

.space-text {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  color: #c0301e;
  font-weight: 600;
}

.bolded {
  font-weight: 600;
}

.gap {
  gap: 2rem;
}

@media screen and (max-width: 430px) {
  .login-text-mobile {
    font-size: 12px;
  }
}
