.help-container {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  padding: 1rem 0;
  color: #090081;
  text-transform: uppercase;

  a {
    color: #090081;
    width: 180px;
    text-align: center;
    text-decoration: none;
    padding-right: 2rem;
    border-right: 2px solid #090081;

    &:last-child {
      border-right: inherit;
      padding-right: 0;
    }

    img {
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 14px;
    }
  }
}
