@import '../variable/Theme.scss';

footer > div > div p {
  font-size: 14px;
}

footer {
  font-family: Helvetica, Arial, sans-serif;
  gap: 11rem;
  text-align: center;
  color: white;
  padding: 0.7rem 3rem;
  background: linear-gradient(to right, #28356a 0%, #059be0 51%, #28356a 100%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  .grid-column {
    box-sizing: border-box;
    width: 140px;
    margin: 0 auto;
    position: relative;
    bottom: -9px;

    div {
      padding: 0.7rem 0 !important;
    }

    h5 {
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
      opacity: 0.9;
    }

    p {
      opacity: 0.9;
    }

    a {
      display: inline-block;
      opacity: 0.9;
      font-size: 14px;
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }

    .icon-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.4rem !important;
    }

    .app-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0 !important;
      gap: 0.3rem;
      opacity: 0.9;

      img:nth-child(1) {
        margin: 0 auto;
      }

      p:nth-child(2) {
        width: 105px;
        color: white;
        font-weight: 600;
        background: #86bc40;
        border-radius: 1.7rem;
        padding: 0.6rem 0.3rem !important;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }

  .social-networks {
    display: flex;
    justify-content: center;
    gap: 0.7rem;
  }

  div:nth-child(5) {
    position: relative;
    bottom: 1.2rem;
  }

  .icon-fl {
    filter: grayscale(1) invert(1);
    position: relative;
    top: -14px;
  }

  div:nth-child(5) > div:nth-child(2) > a,
  div:nth-child(5) > div:nth-child(3) > a {
    font-size: 11px;
  }
}

svg {
  color: white;
}

@media screen and (max-width: 888px) {
  footer {
    gap: 0;
    padding: 0.6rem 0 !important;

    .grid-column {
      width: 110px;
    }

    .icon-link {
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: 667px) {
  footer {
    gap: 0;
    grid-template-columns: 1fr;
    padding: 0.6rem 0 !important;

    .grid-column {
      width: 230px;
      padding: 0.6rem 0 !important;
    }

    .icon-link {
      padding: 0 !important;
    }
  }
}
