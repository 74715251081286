@import '../variable/Theme.scss';

.popup-overlay {
  background-color: rgba(10, 10, 10, 0.6);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
}

.popup-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 520px;
  height: 29vh;
  z-index: 250000;
  padding: 0.5rem;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  font-size: 19px !important;
  background-color: rgba(250, 250, 250, 0.9);

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  animation: fade 1s 0.5s forwards;
  opacity: 0;

  p {
    font-family: Helvetica, Arial, sans-serif;
    color: darkblue;
  }

  &__animation {
    @keyframes fade {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    animation: fade 2s 1s forwards;
    opacity: 0;
  }

  &__msg {
    font-style: italic;
    padding: 0.1rem 0 !important;
  }

  &__msg-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;

    svg {
      transform: translate(-10px, 5px);
      font-size: 35px;
      color: darkblue;
    }
  }

  &__button {
    font-size: 24px !important;
    display: flex;
    padding: 1rem;
    gap: 1rem;
    cursor: pointer;

    button {
      color: white;
      border: none;
      background: $bg-gradient-button;
      padding: 0.5rem 1.5rem;
      border-radius: 1.5rem;
    }
  }
}

@media screen and (max-width: 560px) {
  .popup-content {
    font-size: 16px !important;
    width: 222px;
    height: 240px;
    z-index: 200000;
    border-radius: 1.5rem;

    &__button {
      font-size: 24px !important;
      padding: 0.3rem 0.7rem;
      border-radius: 0.5rem;
      flex-direction: column;
    }

    &__msg-timer {
      margin-bottom: 0.8rem;
    }
  }
}
