@import '../variable/Theme.scss';

h2 {
  font-family: Helvetica, Arial, sans-serif;
  color: #090081;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.contact-page {
  font-family: Helvetica, Arial, sans-serif;
  color: #090081;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmcfui1 {
  text-align: center;
  z-index: 1;
  text-transform: uppercase;
  border: none;
  box-shadow: 2px 2px 4px 3px #891915;
  border: 1px solid #ececec;
  border-radius: 10px;
  overflow: hidden;
  max-width: 400px;
  margin: 50px auto;
  padding: 3rem;

  div:nth-child(4) > textarea {
    height: 150px;
  }
}

p {
  padding: 0.9rem 0;

  &:nth-child(2),
  &:nth-child(4) {
    padding-bottom: 0.1rem;
  }
}

a {
  font-style: italic;
  color: inherit;
}

@media screen and (max-width: 590px) {
  .cmcfui1 {
    margin-bottom: 10px;
    box-shadow: 2px 2px 2px 2px #891915;
    border: 1px solid #891915;
    max-width: 300px;
    margin: 20px auto;
    padding: 1rem;
  }
}
