@import '../variable/Theme.scss';

div.page-content > main > div > div > div > div {
  text-align: center;
}

form {
  text-transform: uppercase;
  color: #090081;
  padding: 1rem;

  label {
    display: block;
    margin-top: 0.5rem;
    padding: 0.5rem 0;
  }

  input {
    border-radius: 1rem;
    padding: 0.2rem;
    text-align: center;
    width: fit-content;
    margin: 0.5rem auto;

    ::placeholder {
      color: #cbd0d5;
    }
  }

  input,
  textarea {
    display: block;
    justify-content: center;
    margin: 0 auto;
  }

  .back-btn {
    background: #090081;
    border: none;
    border-radius: 0.5rem;
    font-size: 30px;
    padding: 0.5rem 0.3rem 0.1rem 0.3rem;
    width: fit-content;
    margin: 0 auto !important;
    display: flex !important;
    cursor: pointer;
  }

  .valid-btn {
    padding: 0.6rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
    background: #090081;
    border: none;
    border-radius: 0.5rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      opacity: 0.8 !important;
    }

    svg {
      font-size: 40px;
    }
  }

  .loading-msg {
    height: 100vh;
  }

  .errors-msg {
    color: #c0301e;
    padding: 0.5rem 0;
  }
}

.user-edit {
  max-width: 600px;
  margin: 0 auto;
}

.personnal-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.9rem 0;
}

.select-level {
  text-align: center;
  border-radius: 0.9rem;
  border: 2px solid #2e3750;
  padding: 0.2rem 0.8rem;
  color: #2e3750;
  min-width: 12em;
}

.back-button-edit {
  border-radius: 0.5rem;
  border: 1px solid darkred;
  background: $bg-gradient-button;
}

.text-align-c {
  text-align: center;
}

.change-btn-unblocked {
  background: #891915;
}

@media screen and (max-width: 650px) {
  .personnal-pagination {
    flex-direction: column;
  }
}
