* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@mixin background-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 100vh;
  width: 100vw;
  content: '';
  height: -webkit-fill-available;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
}

.page-content {
  flex: 1;
  position: relative;
}

main {
  height: 100%;
}

main::before {
  background-image: url('./assets/images/acronyms/1_sigle_FL_noir.png');
  opacity: 0.06;
  z-index: -10;
  @include background-image;
}

main::after {
  background-image: url('./assets/images/elements/guilloche_ronde.png');
  z-index: -11;
  opacity: 0.18;
  @include background-image;
}

.page-msg {
  flex: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  button {
    padding: 0.6rem 1rem;
  }
}
