@import '../variable/Theme.scss';

@mixin all-icons {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.6rem 0;
  color: white;
  cursor: pointer;
}

.list-navigation-h {
  .items {
    font-family: Helvetica, Arial, sans-serif;
  }

  button {
    color: #f1f1f1;
    text-decoration: none;
    font-family: Helvetica, Arial, sans-serif;
    font-style: italic;
  }

  button:hover {
    opacity: 0.8;
    transform: scale(1);
    text-decoration: underline;
  }
}

.items-nav {
  color: #f1f1f1;
  text-decoration: none;
  list-style-type: none;
  background: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

nav {
  position: relative;
  top: 0;
  width: 100%;

  .list {
    background: $bg-gradient-menu;
    list-style-type: none;
    height: 90px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // padding-left: 2.5rem;

    .title-page {
      gap: 0;
      margin-left: 3.5rem;

      &:hover {
        border-radius: 10%;
        border: 1px solid white;
      }
    }

    .list-navigation-h {
      display: flex;
      gap: 1rem;
    }

    .list-navigation-h .items {
      text-align: center;
      gap: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #f1f1f1;
      cursor: pointer;
      text-decoration: none;

      :hover {
        opacity: 0.8;
        text-decoration: underline;
      }

      :active {
        transform: scale(1.1);
      }

      .icons {
        position: relative;
        bottom: 3px;
        font-size: 31px;
      }
    }

    p {
      font-size: 11px;
      font-weight: 600;
    }
  }
}

.burger-menu-container,
.burger-button {
  display: none;
}

@media screen and (max-width: 992px) {
  .list-navigation-h .items {
    font-weight: 800;
  }
}

// BurgerMenu
@media screen and (max-width: 667px) {
  .burger-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;

    li {
      list-style: none;
    }

    a,
    .items-nav {
      @include all-icons;
    }

    div > li:nth-child(3) > button {
      @include all-icons;
      background: none;
      border: none;
    }
  }

  .list {
    padding-left: 2.5rem;
  }

  .burger-button {
    display: flex;
    font-size: 33px;
    cursor: pointer;
  }

  nav .list .list-navigation-h {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  nav .list .title-page {
    margin-right: 0;
  }
}

@media screen and (max-width: 667px) {
  //   _::-webkit-full-page-media, _:future, :root .safari_only {
  //     background-color:#CCCCCC;
  //   }
  //   @media not all and (min-resolution:.001dpcm) { @media {
  //     .safari_only {
  //         background-color:#CCCCCC;
  //     }
  // }}
  .item-nav-btn {
    font-family: Helvetica, Arial, sans-serif;
    padding: 0 !important;
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
  }

  button:hover {
    transform: scale(1) !important;
    // opacity: 0.8;
    // text-decoration: underline;
  }
}

@media screen and (max-width: 500px) {
  nav .list .title-page {
    gap: 0;
    margin-right: 1rem;
  }

  .list-navigation-h .items {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 333px) {
  .list-navigation-h .items {
    font-size: 12px !important;
  }

  nav .list .title-page {
    margin-left: 0.5rem;
  }
}
