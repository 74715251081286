.table-container {
  overflow: scroll;
  max-width: 84vw;
  margin: 0 auto;
  background: linear-gradient(
    to right,
    rgba(137, 25, 21, 0.05) 0%,
    rgba(9, 0, 129, 0.05) 51%,
    rgba(137, 25, 21, 0.05) 100%
  );
}

table {
  border: thick double #891915;
  // min-width: 1430px;
  width: max-content;

  thead,
  tbody {
    display: block;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    border-collapse: separate;
    border-spacing: 4px;
  }

  tr {
    width: 100%;
    gap: 0.5rem;

    td,
    th {
      min-width: 2rem;
      border-spacing: 0;
      padding: 0.1rem 0.6rem;
    }

    td {
      word-break: break-all;
      border-right: 1px solid gray;
    }
  }
}

@mixin user-list-btn {
  color: white;
  background: #090081;
  border: none;
  border-radius: 0.5rem;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: 1rem;
  // margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

main {
  text-transform: uppercase;
  color: #090081;

  .back-btn {
    background: #090081;
    border: none;
    border-radius: 1rem;
    font-size: 30px;
    padding: 0.7rem 0.5rem 0.3rem 0.5rem;

    &:hover {
      opacity: 0.8;
    }
  }

  input {
    font-family: inherit;
    line-height: inherit;
    color: #2e3750;
    min-width: 12em;
  }
}

.personnal-pagination {
  display: flex;
}

.create-btn {
  @include user-list-btn;
  padding: 0.4rem;
  font-size: 13px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    font-size: 35px;
  }
}

.change-btn {
  @include user-list-btn;
  padding: 0.2rem;

  :hover {
    opacity: 0.8;
  }
}

.change-btn-disabled {
  background: lightgray;
}

.change-btn-blocked {
  background: teal;
}

.change-btn-unblocked {
  background: #891915;
}

.flex-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.3rem;

  button {
    width: 100%;
    padding: 0.2rem;

    &:nth-child(3) {
      grid-column: span 2;
    }
  }
}

@media screen and (max-width: 892px) {
  .hide-responsive {
    display: none;
  }

  table {
    // min-width: unset;
    min-width: 540px;
  }

  .table-container {
    width: 54.5vw;
  }
}
