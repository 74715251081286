@import '../variable/Theme.scss';

.shop-page {
  font-family: Helvetica, Arial, sans-serif;
  color: #090081;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-blocs {
    z-index: 1;

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
    }

    img {
      z-index: 2;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

div.page-content > main > div > img {
  height: 500px;
}

@media screen and (max-width: 650px) {
  .img-shop {
    height: 300px !important;
  }
}

// Map container
.leaflet-container {
  height: 100%;
  width: 100%;
}

.shop-map-container {
  height: 80vh;
  width: 90vw;
  padding-bottom: 1rem;
}
